import * as React from "react"
import styled from "styled-components";
import Simple from "@templates/Simple"


const Page = () => {
  return (
    <Simple title={"CRI: Créditos"}>
      <Wrapper>
        <h1>Créditos</h1>
        <ContentWrapper>
          <Content>
            <Section>
              <strong>Instructores</strong>
              <ul>
                <li>Patricia García</li>
                <li>Andrés G. (Willy) Lescano</li>
                <li>Roxana Lescano</li>
              </ul>
            </Section>
            <Section>
              <strong>Coordinación</strong>
              <ul>
                <li>Patricia Mallma</li>
              </ul>
            </Section>
            <Section>
              <strong>Desarrollo de los módulos</strong>
              <ul>
                <li>María Segura</li>
                <li>Percy Mayta-Tristán</li>
                <li>Carmen Rosa García</li>
              </ul>
            </Section>
          </Content>
          <Content>
            <Section>
              <strong>Equipo de diseño del curso</strong>
              <ul>
                <li>Cecilia Caparachin</li>
                <li>César Nureña</li>
                <li>Marcela Lazo E.</li>
                <li>Miguel S. Egoávil</li>
                <li>Renzo Ibáñez</li>
              </ul>
            </Section>
            <Section>
              <strong>Ilustraciones</strong>
              <ul>
                <li>Dafne De Vinatea</li>
              </ul>
            </Section>
          </Content>
        </ContentWrapper>
      </Wrapper>
    </Simple >
  )
}

export default Page;

const Wrapper = styled.div`
margin-bottom: 10em;
padding: 0 1em;
width: 100%;
color: var(--color4);
h1{
  font-size:28px;
  line-height:39.2px;
}
`;
const ContentWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
@media (min-width:768px){
  flex-direction: row;
}
`;
const Content = styled.div`
width:100%;
@media (min-width:768px){
  width:50%;
}
`;
const Section = styled.div`
padding: 0 5em;
  font-size:16px;
  line-height:24px;
`;